<template>
  <v-list
    class="pt-0"
    dense
  >
    <v-subheader
      class="primary subtitle-2"
      dark
    >
      <span class="u-text-overflow">{{ '#' + item.id }}</span>
    </v-subheader>

    <v-list-item
      v-if="item.order_status < 4"
      @click="nextStep"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon> fas fa-arrow-right </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title> Próxima etapa </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      @click="historyOrder"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon>fas fa-history</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title> Hist. de Etapas </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      @click="sendModal"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon>fas fa-paper-plane</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title> Enviar dados </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      @click="showPDF"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon>fas fa-print</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title> Visualizar PDF </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="item.order_status != 7 && showCancelOrder"
      @click="cancelOrder"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon>fas fa-times-circle</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title> Cancelar pedido </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="showDeleteOrders"
      @click="deleteOrder"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon color="error">fas fa-trash</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="error--text"> Excluir </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { contextMenu } from '@/main.js'
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('comercial', ['showCancelOrder', 'showDeleteOrders']),
  },
  methods: {
    ...mapMutations(['setSendEmailLoading']),
    sendModal() {
      this.$store.commit('setModal', {
        show: true,
        component: 'send-email',
        text: 'Qual email deseja enviar o PDF?',
        caption: 'Selecione abaixo.',
        client_id: this.item.budget.client_id,
        confirm: (response) => {
          this.sendPdf(response)
        },
        negate: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
    sendPdf(response) {
      this.setSendEmailLoading()
      this.$api
        .post(`order/pdf`, {
          id: this.item.id,
          body: response.body,
          emails: response.emails,
        })
        .then(() => {
          this.setSendEmailLoading()
          this.$store.commit('setModal', {
            show: false,
          })
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'PDF enviado com sucesso!',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops!',
            caption: 'Falha ao enviar PDF',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
    updateStatus() {
      this.$api
        .patch(`order/${this.item.id}`, {
          status: !this.item.status,
        })
        .then(() => {
          contextMenu.action('orders')

          this.item.status = !this.item.status
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops!',
            caption: 'Falha ao tentar atualizar o status deste Pedido',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
    deleteOrder() {
      this.$store.commit('setModal', {
        show: true,
        component: 'warning',
        text: 'Atenção!',
        caption: 'Deseja mesmo deletar este Pedido?',
        confirm: () => {
          this.$api
            .post(`order/delete`, { id: this.item.id })
            .then(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'success',
                text: 'Pedido excluido com sucesso',
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                  contextMenu.action('orders')
                },
              })
            })
            .catch((err) => {
              this.$store.commit('setModal', {
                show: true,
                component: 'error',
                text: 'Ops! Falha ao tentar excluir este pedido',
                caption: err.response.data.message,
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                },
              })
            })
        },
        negate: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
    cancelOrder() {
      this.$store.commit('setModal', {
        show: true,
        component: 'warning',
        text: 'Atenção!',
        caption: 'Deseja mesmo cancelar este pedido?',
        confirm: () => {
          this.$api
            .post('order/status/cancel', { order_id: this.item.id })
            .then(() => {
              this.item.order_status = 7
              this.$store.commit('setModal', {
                show: true,
                component: 'success',
                text: 'Pedido cancelado com sucesso!',
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                  contextMenu.action('orders')
                },
              })
            })
            .catch(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'error',
                text: 'Ops! Falha ao cancelar o pedido',
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                },
              })
            })
        },
        negate: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
    nextStep() {
      if (!this.item.delivery_date) {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Algo deu errado',
          caption:
            'Você precisa preencher os campos: Data de entrega, Etiqueta e Nome para gravação',
          hasObs: true,
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      } else {
        this.$store.commit('setModal', {
          show: true,
          component: 'warning',
          text: 'Atenção!',
          caption: 'Avançar para a próxima etapa?',
          hasObs: true,
          confirm: (response) => {
            let data = {
              id: this.item.id,
              obs_status: response,
              user_id: this.$cookies.get('id'),
            }
            this.$api
              .patch(`order/status/${this.item.id}`, data)
              .then(() => {
                this.item.order_status++
                this.$store.commit('setModal', {
                  show: true,
                  component: 'success',
                  text: 'Status atualizado com sucesso!',
                  confirm: () => {
                    this.$store.commit('setModal', {
                      show: false,
                    })
                    contextMenu.action('orders')
                  },
                })

                if (this.item.order_status == 4) {
                  this.item.approved_order_date = new Date(
                    Date.now()
                  ).toISOString()
                }
              })
              .catch(() => {
                this.$store.commit('setModal', {
                  show: true,
                  component: 'error',
                  text: 'Ops! Falha ao atualizar o status...',
                  confirm: () => {
                    this.$store.commit('setModal', {
                      show: false,
                    })
                  },
                })
              })
          },
          negate: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      }
    },
    historyOrder() {
      this.$store.commit('setModal', {
        show: true,
        component: 'order',
        text: 'Histórico de Etapas',
        caption: 'do Pedido: #' + this.item.id,
        hasObs: true,
        id: this.item.id,
        confirm: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
        negate: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
    showPDF() {
      this.$api
        .post(`order/seepdf`, {
          id: this.item.id,
          client_company_name: this.item.budget.client.company_name,
        })
        .then((res) => {
          window.open(
            `${this.$store.state.imagePath}${res.data.data}`,
            '_blank'
          )

          contextMenu.action('orders')
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops!',
            caption: 'Falha ao gerar PDF deste orçamento',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
  },
}
</script>
